body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfcfe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1045px) {
  html,
  body {
    max-width: 768px;
    margin: auto;
    width: 768px;
    overflow-x: hidden;
  }
  body {
    border-left: 1px solid #083f85;
    border-right: 1px solid #083f85;
    border-bottom: 1px solid #083f85;
  }
  .makeStyles-header-21 {
    max-width: 768px;
    margin: 0;
    width: 100%;
  }
  .MuiDrawer-modal {
    width: 768px;
    margin: auto;
    overflow: hidden;
  }
  .MuiDrawer-paperAnchorLeft {
    left: auto !important;
    right: 0;
  }
  .MuiDrawer-paper {
    position: absolute !important;
  }
}
