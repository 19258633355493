.preLoginSocial {
  width: 34px;
  height: 34px;
  border: solid 0.4px #d5d5d5;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  cursor: pointer;
}

.primaryInput input {
  height: 36px !important;
  padding: 10px !important;
}

.primaryInput .MuiOutlinedInput-root {
  background: transparent !important;
}

.primaryInput > div {
  background: #fbfbfb !important;
}

.primaryInput > fieldset {
  border: 1px solid #9f9f9f;
}

.pageTitle {
  font-size: 18px !important;
  color: #083f85;
  font-weight: bold !important;
  padding: 0 8px;
}
.dropDown {
  border-radius: 22px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  padding: 9px 35px 8px 15px;
  appearance: none;
  -webkit-appearance: none;
  background: #f6f6f6 url("../images/svg/down-arrow-black.svg") no-repeat
    calc(100% - 15px) center;
  background-size: 13px;
  outline: 0;
  font-size: 14px;
  color: #717171;
  min-width: 140px;
}
.recordBtn {
  width: 96px;
  height: 26px;
  padding: 5px !important;
  border-radius: 18px !important;
  border: solid 0.8px #d5d5d5 !important;
  background-color: #f5f5f5 !important;
  font-size: 12px !important;
  color: #717171 !important;
  opacity: 0.9;
  font-weight: 500 !important;
}

.blueCard {
  background-image: linear-gradient(234deg, #4fc1e9 98%, #3bafda 40%);
}
.darkBlueCard {
  background-image: linear-gradient(234deg, #5d9ece 98%, #4a89dc 40%);
}
.redCard {
  background-image: linear-gradient(234deg, #fc6e51 99%, #e9573f 40%);
}
.yellowCard {
  background-image: linear-gradient(235deg, #ffce54 99%, #f6bb42 40%);
}

@media (max-width: 767px) {
  .primaryInput input {
    height: 36px !important;
    padding: 10px !important;
    font-size: 12px !important;
  }
}

.animationSpin {
  animation: App-logo-spin infinite 4s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
